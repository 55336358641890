'use strict'

###*
 # @ngdoc object
 # @name mundoAdmin.devices
 # @description

###
angular
  .module 'mundoAdmin.devices', [
    'ui.router'
  ]
  .run [
    'MundoNavigation'
    (MundoNavigation) ->
      MundoNavigation.addSection
        state: 'devices'
        label: 'app.admin.devices'
        icon: 'devices_other'
        showExpression: 'hasPermission("access device section")'
        items: [
            state: 'devices.overview'
            label: 'app.admin.pageTitles.devices.devices'
            icon: 'devices_other'
            showExpression: 'hasPermission("view all MundoMosaDeviceBundle:Device entities")'
          ,
            state: 'devices.types'
            label: 'app.admin.pageTitles.devices.deviceTypes'
            icon: 'devices'
            showExpression: 'hasPermission("view all MundoMosaDeviceBundle:Device entities")'
        ]
  ]
